.widgetSelect {}

/*Control*/
.widgetSelect :global(.widgetSelect__control) {
  border: 1px solid rgb(228, 225, 225);
  background: rgb(247, 247, 247);
  border-radius: 5px;
  font-size: 12px;
  height: 100%;
  min-height: 25px;
  padding: 0 10px;

  &:hover {
    border: 1px solid rgb(108, 117, 125);
  }
}

/*Arrow*/
.widgetSelect :global(.widgetSelect__indicator) svg {
  fill: rgb(108, 117, 125);
  width: 16px;
  height: 16px;
}

.widgetSelect :global(.widgetSelect__indicator) {
  cursor: pointer;
}

/*Menu*/
.widgetSelect :global(.widgetSelect__menu) {
  z-index: 1001;
}

.widgetSelect :global(.widgetSelect__menu-list) {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 0.5rem 0;
  max-height: 35vh;
  min-width: 10rem;
}

.widgetSelect :global(.widgetSelect__option) {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0.25rem 0.6em;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fa;
  }
}

.widgetSelect :global(.widgetSelect__option--is-selected) {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;

  &:hover {
    background-color: #007bff;
  }
}

.widgetSelect :global(.widgetSelect__group-heading) {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #a2a2a2;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.5rem 1.5rem;
}

.widgetSelect :global(.widgetSelect__placeholder) {
  color: rgb(108, 117, 125);
  font-size: 12px;
}
